.select-wrap {
  position: relative;
  .selectbox {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $body-color;
    border: 1px solid #d7dadb;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 10px 50px 10px 12px;
    -webkit-appearance: none;
    background: $white url(../../assets/images/Icon/select-down-arrow.svg) right
      12px center no-repeat;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &--warn-passenger-type-input {
      border: solid 1px #ef4e58;
    }
    &--type2 {
      font-size: 16px;
      line-height: 20px;
      padding: 18px 30px 12px 10px;
    }
    &-dropdown {
      position: absolute;
      top: 52px;
      width: 100%;
      background: $white;
      border: 1px solid #aeaeae;
      box-shadow: 0px 1px 1px #e5e5e5;
      padding: 15px;
      z-index: 1;
      li {
        cursor: pointer;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      &.passengers {
        width: 450px;
        &.days {
          width: 100%;
        }
        .label {
          font-weight: 500;
          color: $secondary-text-color;
          margin-bottom: 5px;
          white-space: nowrap;
        }
        .counter {
          &-wrap {
            width: 102px;
            border: 1px solid $primary-brand-color;
          }
          &-btn,
          &-value {
            display: inline-block;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
          }
          &-btn {
            font-weight: 500;
            color: $primary-brand-color;
            cursor: pointer;
          }
          &-value {
            font-weight: 500;
            color: $title-color;
            background: rgba(0, 119, 191, 0.12);
          }
        }
      }
      &.specialFareCode {
        // width: 287px;
        display: inline-block;
        min-width: 360px;
        max-width: 100%;
        width: fit-content;
      }
    }
    &.input-box {
      background: $white;
      white-space: inherit;
      overflow: visible;
      text-overflow: inherit;
      padding: 15px 10px 10px 10px;
      input {
        border: none;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
      }
    }
  }
  label {
    position: absolute;
    top: -8px;
    left: 10px;
    padding: 0 2px;
    background: $white;
    color: $secondary-text-color;
  }
}

.input-wrap {
  select {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    color: $body-color;
    background: $white;
    border-radius: 4px;
    border: 1px solid rgba(154, 157, 158, 0.58);
    transition: all 0.3s ease;
    padding: 7px 30px 7px 10px;
    -webkit-appearance: none;
    background: $white url(../../assets/images/Icon/select-down-arrow.svg) right
      12px center no-repeat;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

select {
  &:focus {
    border: 1px solid $primary-brand-color;
  }
}
