body {
  background: $background-color;
}

.black {
  color: $black !important;
}

.primary-text-color {
  color: $primary-text-color !important;
}

.ml-auto {
  margin-left: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.p-40 {
  padding: 40px;
}

.w-100 {
  width: 100%;
}

.hidden {
  opacity: 0 !important;
}

h1.title1 {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

h1.title1--bold {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

h6.title6 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

p.sub-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: $secondary-text-color;
}

.link {
  font-weight: 700;
  color: $primary-brand-color;
  transition: all 0.3s ease;

  &:hover {
    color: darken($primary-brand-color, 10%);
    // color: $primary-brand-color-dark;
  }
}

.section-body {
  margin: 0 auto;
  height: calc(100vh - 50px);
  background: $background-color;
  padding: 20px 30px;
  overflow-y: auto;
}

.bread-crumbs {
  a {
    color: $secondary-text-color;

    &:not(:last-child) {
      &::after {
        content: "/";
        margin: 5px;
      }
    }

    &.active {
      font-weight: 500;
      color: $black;
      pointer-events: none;
    }
  }
}

.card {
  background: $white;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}

.card-min-height {
  min-height: 275px;
}

.label-value-wrap {
  .label {
    color: $grey;
    margin-bottom: 6px;
  }

  .value {
    font-size: 16px;
    line-height: 19px;
  }
}

.password-strength-text {
  li {
    color: $grey;

    &::before {
      content: "\E40C";
      display: inline-block;
      color: #ccc;
      margin-right: 5px;
      font-family: "Material Icons";
      font-size: 16px;
      position: relative;
      top: 3px;
    }

    &.valid {
      &::before {
        content: "\E86C";
        color: $green;
      }
    }
  }

  &.error {
    li {
      color: $secondary-brand-color;
    }
  }
}

button:disabled {
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}

.disabled {
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}

.empty-data {
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}

.error {
  color: red;
  font-size: 13px;
}

.warning {
  color: $primary-brand-color;
  font-size: 13px;
}

.container-dropdown {
  position: relative;

  .option-selected {
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid rgba(154, 157, 158, 0.58);
    border-radius: 3px;
    background: #fff url(../../assets/images/Icon/select-down-arrow.svg) right
      12px center no-repeat;
    height: 34px;
    font-weight: 500;
  }

  .dropdown-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(154, 157, 158, 0.58);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border-top: 0;
  }

  .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;

    li.dropdown-menu__item {
      padding: 10px;
      border-bottom: 1px solid rgba(218, 218, 218, 0.58);
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: #f5f4f4;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px !important;
  }
}

//Slider
.horizontal-slider {
  width: 100%;
  // max-width: 500px;
  height: 50px;
  // border: 1px solid grey;
}

.vertical-slider {
  height: 380px;
  width: 50px;
  // border: 1px solid grey;
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: $white;
  color: white;
  cursor: pointer;
  // border: 5px solid gray;
  box-sizing: border-box;
}

// .example-thumb.active {
//     background-color: grey;
// }

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-1 {
  background: $primary-brand-color;
}

.example-track.example-track-2 {
  // background: #0f0;
  background: #ddd;
}

.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}

.horizontal-slider .example-thumb {
  top: 12px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  // border-radius: 50%;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 1 !important;
}

.horizontal-slider .example-thumb span {
  color: $grey;
  position: absolute;
  top: 25px;
  left: 50%;
  font-weight: 500;
  transform: translateX(-50%);
  white-space: nowrap;
}

.vertical-slider .example-thumb {
  left: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
}

.vertical-slider .example-track {
  left: 20px;
  width: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.status-pill {
  padding: 2px 15px;
  color: #003edf;
  background: #f7f9ff;
  border: 1px solid #003edf;
  border-radius: 15px;
  display: inline-block;
  position: relative;

  &.Ticketed {
    color: #00a558;
    background: #e9f9f2;
    border: 1px solid #00a558;

    &.retained {
      color: #f5a91a;
      background: rgba(245, 169, 26, 0.1);
      border: 1px solid #f5a91a;
    }
  }

  &.Cancelled {
    color: #f93d49;
    background: rgba(249, 61, 73, 0.1);
    border: 1px solid #f93d49;
    &.retained {
      color: #f5a91a !important;
      background: rgba(245, 169, 26, 0.1) !important;
      border: 1px solid #f5a91a !important;
    }
  }

  &.Void {
    color: #f5a91a;
    background: rgba(245, 169, 26, 0.1);
    border: 1px solid #f5a91a;
  }

  span {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 31px;
    left: 0;
    // min-width: 250px;
    white-space: nowrap;
    background: white;
    padding: 5px 15px;
    border: 1px solid;
    border-radius: 5px;
    transition: all 0.3s ease;
    z-index: 2;
  }

  &:hover {
    span {
      opacity: 1;
      visibility: visible;
    }
  }
}

.transaction-allowed {
  color: #00a558 !important;
  background: #e9f9f2;
  border: 1px solid #00a558;
  padding: 2px 10px;
  border-radius: 15px;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: "";
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    background: white;
    border: 2px solid #f93d49;
  }

  &:hover + label:before {
    opacity: 0.9;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: #f35429;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.m-b-0 {
  margin-bottom: 0 !important;
}
.m-t-0 {
  margin-top: 0 !important;
}

.price-breakup .table-body table tfoot tr td.td-normal {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #52545b !important;
}

.price-breakup .table-body table tfoot tr td.td-normal-md {
  font-weight: 500 !important;
}

.tool-tip-wrapper.info {
  position: relative;

  .info {
    color: $primary-brand-color;
  }

  .tool-tip {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    .tool-tip {
      opacity: 1;
      visibility: visible;
    }
  }

  .tool-tip {
    position: absolute;
    color: #fff;
    border-radius: 4px;
    padding: 5px;
    background: $primary-brand-color;
    width: 300px;
    top: 0px;
    left: -310px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    border: 1px solid #efefef;
    text-align: left;
  }
}

.add-more-button,
.remove-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #f93d49;
}

.m-auto {
  margin: 0 auto;
}

.section-container {
  max-width: 1440px;
  margin: 0 auto;
}

.iata-dropdown .selectbox-dropdown {
  width: max-content !important;
  right: -27px;
}

.label.not-allowed {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.button-proceed {
  position: relative;

  .tooltip {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -30px;
    left: 0;
    font-size: 16px;
    font-weight: 500;
    color: #0077bf;
    transition: all 0.3s ease;
  }

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}

.pos-relative {
  position: relative;
}

.pointer-none {
  pointer-events: none;
}
.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 2px !important;
}
.input-wrap select.react-datepicker__month-select,
.input-wrap select.react-datepicker__year-select {
  padding: 5px 30px 5px 10px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: 1.2rem !important;
}
.react-datepicker__header {
  padding-top: 5px !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-bottom: 4px !important;
}
.react-datepicker__navigation {
  top: 7px !important;
}
// @media only screen
// and (min-device-width : 768px)
// and (max-device-width : 1370px){
//   .react-datepicker__day, .react-datepicker__day-name, .react-datepicker__time-name {
//     line-height: 17px!important;
// }
// }

.react-datepicker-popper {
  position: absolute !important;
  z-index: 9999 !important;
}

.react-datepicker-popper[data-placement^="top"],
.react-datepicker-popper[data-placement^="bottom"] {
  margin: 0 !important;
}

.react-datepicker-popper[data-placement^="top-start"],
.react-datepicker-popper[data-placement^="bottom-start"] {
  margin-top: 10px !important;
  margin-bottom: 6px !important;
}

.search-airline-dropdown {
  .list-label {
    width: 85%;
  }
  .airline-logo {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 2px;
    }
  }
  .owner-code {
    font-size: 16px;
    font-weight: 500;
  }
  .name {
    white-space: nowrap;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 65%;
  }
}

.search-airline-dropdown-special-fare {
  .list-label {
    width: auto;
  }
  .airline-logo {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 2px;
    }
  }
  .owner-code {
    font-size: 16px;
    font-weight: 500;
  }
  .name {
    white-space: nowrap;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
  }
}
.dropdown-airline-list {
  .icon {
    background-color: #fff;
    border: 1px solid #ccc;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    font-family: "Roboto";
    &::before {
      content: "";
      position: relative;
      display: inline-block;
      font-family: "Material Icons";
      font-size: 16px;
      color: #fff;
    }
    &.selected {
      background-color: #219653;
      border: none;
      width: 20px;
      height: 20px;
      &::before {
        content: "\e5ca";
      }
    }
  }
}
.search-result-dropdown {
  width: 250px;
  label {
    font-size: 12px;
  }
  .select-wrap {
    .selectbox {
      padding: 8px 10px 7px 10px;
      font-size: 14px;
      line-height: normal;
    }
    .selectbox-dropdown {
      top: 34px;
    }
  }
}
.search-result-dropdown-reshop {
  min-width: 350px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  
  .label-wrapper {
    font-size: 16px;
    margin: 6px; 
    flex-shrink: 0;
    position: relative; 
    padding-left: 8px;
    &::before {
      content: "";
      position: absolute;
      left: 0px; 
      top: 0;
      bottom: 0;
      width: 1px;
      height: 98%;
      background-color: #ccc; 
    
  }
}
 
  .select-wrap {
    flex-grow: 1;
    min-width: 0; 
    .selectbox {
      padding: 8px 10px 7px 10px;
      font-size: 14px;
      line-height: normal;
    }
    .selectbox-dropdown {
      top: 34px;
      max-height: 150px; 
      overflow-y: auto;
    }
  }
}
.search-result-input {
  width: 110px;
  label {
    font-size: 12px;
  }
  .select-wrap .selectbox.input-box {
    padding: 0 10px 0 0;
    overflow: hidden;
    input {
      text-transform: none;
      font-size: 14px;
      padding: 6px 5px;
    }
  }
}
.search-airline-dropdown {
  .selectbox-dropdown {
    z-index: 10 !important;
  }
}
.search-airline-dropdown-special-fare{
  .selectbox-dropdown {
    z-index: 10 !important;
  }
}
